import React, { useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';

import Layout from '../layout';
import SEO from '../seo';
import ConnectPage from '../Connect/spotifyConnect';
import { StateContext } from '../../utils/globalState';

const SpotifyConnect = ({
  pageContext,
  data: {
    prismicConnect: { data },
  },
}) => {
  const { first, second, third } = useContext(StateContext);

  useEffect(() => {
    if (!first || !second || !third) {
      navigate(`/${pageContext.territory}`);
    }
  }, []);

  return (
    <Layout>
      <SEO
        title="Visit California"
        pageUrl="/connect"
        description="Visit California"
        twitterUsername="@VisitCA"
        lang={pageContext.territory}
      />
      <ConnectPage lang={pageContext.territory} cms={data} />
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicConnect(lang: { eq: $lang }) {
      data {
        connect_button {
          text
        }
        connect_text {
          text
        }
        connect_title {
          text
        }
        connect_trackplaceholder {
          text
        }
        connect_tracksearch {
          text
        }
      }
    }
  }
`;

export default SpotifyConnect;
