/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/button-has-type */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Sine, TweenMax, TimelineMax } from 'gsap';
import { navigate } from 'gatsby';
import Svg from 'react-inlinesvg';
import RacApi from '../../utils/api';
import { StateContext } from '../../utils/globalState';

/* SVG */
import backArrow from '../../images/svgs/arrow_right.svg';

const ModalWrapper = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .search_mssg {
    color: #3d4643;
    font-family: 'Geometr415 Lt BT';
    font-size: 0.875rem;
    letter-spacing: 0.05rem;
    line-height: 0.8125rem;
    margin-top: 0.625rem;
  }
`;

const ModalContent = styled.div`
  margin: 85px 0px 0px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-top: 137px;
  }

  @media (min-width: 1024px) {
    margin-top: 14.375rem;
  }
`;

const Placeholder = styled.span`
  transform: translate(23px, 15px);
  color: #ffffff;
  font-family: 'Trend Sans';
  font-size: 0.625rem;
  letter-spacing: 0.05rem;
  line-height: 0.8125rem;
  z-index: -1;

  &.placeholder-selected {
    transform: translate(1.4375rem, -0.53125rem);
    transform-origin: left;
    transition: all 0.3s ease-in-out;
  }
`;

const ModalInput = styled.input`
  background: transparent;
  border: 0px;
  width: 14.75rem;
  border-bottom: 0.0625rem solid #ffffff !important;
  padding: 0px 1.375rem 0.75rem;
  color: #ffffff;
  font-family: 'Trend Sans';
  font-size: 1rem;
  letter-spacing: 0.08rem;
  line-height: 1.1875rem;
  border-radius: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  :focus {
    outline: none;
  }
`;

const Back = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: 20px;
  top: 20px;

  h4 {
    font-family: 'Trend Sans';
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #ffffff;
  }

  @media (min-width: 768px) {
    left: 3.125rem;
    top: 2.625rem;
  }
`;

const Arrow = styled(Svg)`
  margin: 0px 1rem 0px 0px;
  transform: rotate(180deg);
  height: 1rem;
  width: 1.5rem;

  * {
    fill: #ffffff;
  }
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  width: 17.5rem;

  .track {
    color: #ffffff;
    background: transparent;
    border: 0px;
    text-align: left;
    padding: 1.0625rem 1.3125rem;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;
    border-radius: 0.5rem;
    margin-top: 0.21875rem;

    :focus {
      outline: none;
    }

    :hover {
      background-color: #ffffff;

      * {
        color: #3d4643;
      }
    }
  }

  .track-text__container * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .track__name {
    font-family: 'Trend Sans';
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  .track__artist {
    font-family: 'Geometr415 Lt BT';
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }
`;

const PlaylistLoader = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-bullet {
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    background: #ffffff;
  }

  .second {
    margin: 0px 0.5rem;
  }
`;

const Modal = ({ lang, closeModal, cms }) => {
  const { updateState, first, second, third } = useContext(StateContext);
  const [status, setStatus] = useState('idle');
  const [tracks, setTracks] = useState([]);
  const [trackSelected, setTrackSelected] = useState(false);
  const [inputSelected, setInputSelected] = useState(false);

  let timer;

  const loaderAnim = () => {
    const tl = new TimelineMax({ repeat: -1, repeatDelay: 0.2 });
    const tl2 = new TimelineMax({ repeat: -1, repeatDelay: 0.1 });
    const tl3 = new TimelineMax({ repeat: -1 });

    tl.to('.first', 0.3, { y: '2rem', ease: Sine.easeInOut }).to('.first', 0.3, {
      y: 0,
      ease: Sine.easeInOut,
    });

    tl2.to('.second', 0.3, { y: '2rem', ease: Sine.easeInOut, delay: 0.1 }).to('.second', 0.3, {
      y: 0,
      ease: Sine.easeInOut,
    });

    tl3.to('.third', 0.3, { y: '2rem', ease: Sine.easeInOut, delay: 0.2 }).to('.third', 0.3, {
      y: 0,
      ease: Sine.easeInOut,
    });
  };

  const performSearch = query => {
    const trackSearch = new RacApi({ url: 'api/search/tracks' });

    trackSearch
      .submit({ query })
      .then(response => {
        if (response.data.tracks.length) {
          setStatus('success');
          setTracks(response.data.tracks);
        } else {
          setStatus('no-tracks');
          setTracks([]);
        }
      })
      .catch(() => {
        setStatus('error');
        setTracks([]);
      });
  };

  const handleChange = event => {
    const { value } = event.currentTarget;

    if (timer !== undefined) {
      clearTimeout(timer);
    }

    if (trackSelected) return;

    if (value.length) {
      setStatus('loading');

      timer = setTimeout(() => {
        performSearch(value);
      }, 100);
    } else if (value.length === 0) {
      setStatus('idle');
      setTracks([]);
    }
  };

  const moveEnterText = () => {
    setInputSelected(true);
  };

  const selectTrack = event => {
    setTrackSelected(true);

    let track = '';
    if (event) {
      const item = event.currentTarget;
      track = item.dataset;

      /* Set placeholer for input during load animation */
      document.querySelector('.add_name').value = track.track;
    }

    const trackSubmit = new RacApi({ url: 'api/playlists/generator', method: 'post' });
    trackSubmit
      .submit({
        type: 'tracks',
        territory: lang,
        id: [track.id],
        q1: first,
        q2: second,
        q3: third,
      })
      .then(response => {
        updateState({
          playlist: response.playlist,
          trips: response.trip.personality,
        });
        navigate(`/${lang}/your-playlist`);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const trackElems = tracks.map(track => (
    <button
      key={track.id}
      className="track"
      data-uri={track.uri}
      data-artist={track.artists[0].name}
      data-track={track.name}
      data-id={track.id}
      onClick={selectTrack}
      data-event-label="search result track"
    >
      <div className="track-text__container">
        <p className="track__name">{track.name}</p>
        <p className="track__artist">{track.artists[0].name}</p>
      </div>
    </button>
  ));

  /* Search Message */
  let searchMsg;
  switch (status) {
    case 'loading':
      searchMsg = 'loading...';
      break;

    case 'no-tracks':
      searchMsg = 'No tracks found';
      break;

    case 'error':
      searchMsg = 'There was an error, please try again.';
      break;

    default:
      searchMsg = '';
      break;
  }

  useEffect(() => {
    TweenMax.to('.modal_fade', 0.4, {
      opacity: 1,
      ease: Sine.easeInOut,
    });

    /* Reset track selected for future use */
    return () => setTrackSelected(false);
  }, []);

  useEffect(() => {
    if (trackSelected) {
      loaderAnim();
    }
  }, [trackSelected]);

  return (
    <ModalWrapper className="modal_fade">
      <Back onClick={closeModal} data-event-label="close track modal">
        <Arrow src={backArrow} />
        <h4>Back</h4>
      </Back>
      <ModalContent className="transform">
        <Placeholder className={inputSelected && 'placeholder-selected'}>
          {cms.connect_trackplaceholder.text}
        </Placeholder>
        <ModalInput
          type="text"
          onChange={handleChange}
          onClick={moveEnterText}
          className="add_name"
          data-event-label="modal track active"
        />
      </ModalContent>
      <div className="transform">
        {!trackSelected ? (
          <React.Fragment>
            {status === 'success' ? (
              <SearchResults>{trackElems}</SearchResults>
            ) : (
              <div className="search_mssg">{searchMsg}</div>
            )}
          </React.Fragment>
        ) : (
          <PlaylistLoader>
            <div className="loading-bullet first" />
            <div className="loading-bullet second" />
            <div className="loading-bullet third" />
          </PlaylistLoader>
        )}
      </div>
    </ModalWrapper>
  );
};

export default Modal;
