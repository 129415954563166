/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Sine, TweenMax } from 'gsap';
import Cookies from 'js-cookie';
// import { navigate } from 'gatsby';
import RacApi from '../../utils/api';
import { StateContext } from '../../utils/globalState';

/* Components */
import Header from '../header';
import Modal from './modal';
import Footer from '../footer';

const ConnectWrapper = styled.div`
  height: 100vh;
  min-height: 733px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    min-height: 1024px;
  }

  @media (min-width: 1024px) {
    min-height: 46.25rem;
  }
`;

const InnerConnect = styled.div`
  transform: translateY(-28%);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  opacity: 0;

  @media (min-width: 768px) {
    transform: translateY(-24%);
  }

  @media (min-width: 1024px) {
    transform: translateY(-20%);
  }
`;

const Title = styled.div`
  font-family: 'Trend Slab';
  font-size: 22px;
  line-height: 28px;
  margin: 77px 0px 0px;

  @media (min-width: 768px) {
    margin: 203px 0px 0px;
    font-size: 2.25rem;
    line-height: 2.6875rem;
  }

  @media (min-width: 1024px) {
    margin: 4.75rem 0px 0px;
  }
`;

const Divider = styled.div`
  height: 0.1875rem;
  width: 2.5rem;
  border-radius: 0.09375rem;
  background-color: #ffffff;
  margin: 15px 0px 17px;

  @media (min-width: 768px) {
    margin: 0.75rem 0px 2rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.125rem;
  line-height: 23px;
  padding: 0px 25px;
  font-family: 'Geometr415 Lt BT';
  height: 104px;
  max-width: 505px;

  @media (min-width: 768px) {
    line-height: 1.5625rem;
    /* width: 507px; */
    max-width: none;
    padding: 0px;
    width: 455px;
    height: auto;
  }

  @media (min-width: 1024px) {
    width: 24.375rem;
  }
`;

const ConenctButton = styled.button`
  margin: 14px 0px 21px;
  height: 3.125rem;
  width: 16rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  font-family: 'Trend Sans';
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0.1875rem 0px 0px;
  cursor: pointer;
  color: #3d4643;
  letter-spacing: -0.03125rem;
  transition: all 0.25s ease-in-out;
  border: 0px;

  :focus {
    outline: none;
  }

  :hover {
    background-color: #ffd100;
    border-radius: 0.5rem;
  }

  @media (min-width: 768px) {
    margin: 2.375rem 0px 1.4375rem;
  }
`;

const TrackSearch = styled.button`
  color: #ffffff;
  font-family: 'Trend Sans';
  font-size: 0.75rem;
  line-height: 0.875rem;
  background: transparent;
  border: 0px;
  text-decoration: underline;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  :hover {
    opacity: 0.6;
  }

  :focus {
    outline: none;
  }
`;

const ConnectPage = ({ lang, cms }) => {
  const { updateState, first, second, third, journey } = useContext(StateContext);
  const [modal, setModal] = useState(false);

  const openModal = () => {
    TweenMax.to('.connect_fade', 0.4, {
      opacity: 0,
      ease: Sine.easeInOut,
      onComplete: () => setModal(true),
    });
  };

  const closeModal = () => {
    TweenMax.to('.modal_fade', 0.4, {
      opacity: 0,
      ease: Sine.easeInOut,
      onComplete: () => {
        setModal(false);
        TweenMax.to('.connect_fade', 0.6, {
          opacity: 1,
          ease: Sine.easeInOut,
        });
      },
    });
  };

  const connectToSpotify = () => {
    const connect = new RacApi({ url: 'connect' });

    Cookies.set('journey', journey);
    Cookies.set('first', first);
    Cookies.set('second', second);
    Cookies.set('third', third);

    const params = { territory: lang };

    if (process.env.NODE_ENV === 'development') {
      params.env = 'dev';
    }

    connect
      .submit(params)
      .then(response => {
        window.location.href = response.url;
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    TweenMax.to('.connect_fade', 0.4, {
      opacity: 1,
      delay: 0.55,
      ease: Sine.easeInOut,
    });
  }, []);

  useEffect(() => {
    switch (true) {
      case (second == 1 || second == 2 || second == 3 || second == 4) && third == 1:
        updateState({ journey: 'family' });
        break;

      case second == 1 && (third == 2 || third == 3):
        updateState({ journey: 'luxury' });
        break;

      case second == 2 && (third == 2 || third == 3):
        updateState({ journey: 'historian' });
        break;

      case second == 3 && (third == 2 || third == 3):
        updateState({ journey: 'rock' });
        break;

      case second == 4 && (third == 2 || third == 3):
        updateState({ journey: 'nature' });
        break;

      default:
        break;
    }
  }, []);

  return (
    <ConnectWrapper>
      {cms && (
        <React.Fragment>
          {!modal ? (
            <InnerConnect className="connect_fade">
              <Header />
              <Title>{cms.connect_title.text}</Title>
              <Divider />
              <Paragraph>{cms.connect_text.text}</Paragraph>
              <ConenctButton onClick={connectToSpotify} data-event-label="connect button">
                {cms.connect_button.text}
              </ConenctButton>
              <TrackSearch onClick={openModal} data-event-label="open track modal">
                {cms.connect_tracksearch.text}
              </TrackSearch>
            </InnerConnect>
          ) : (
            <Modal closeModal={closeModal} lang={lang} cms={cms} />
          )}
        </React.Fragment>
      )}
      <Footer />
    </ConnectWrapper>
  );
};

export default ConnectPage;
